
import { defineComponent, computed, toRef, PropType } from 'vue'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'
import { formatDate } from '@/helpers'
import { NewsItem } from '@/models/NewsItem'
import CkeditorModule from '@/components/CkeditorModule.vue'

export default defineComponent({
  components: {
    CkeditorModule
  },
  props: {
    newsItem: {
      type: Object as PropType<NewsItem>,
      required: true
    },
    featured: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const maxWidth = 297
    const imageHeight = 166

    const featuredImageSrcset = computed(
      () =>
        `${featuredImageUrl.value.small} 391w,
       ${featuredImageUrl.value.half} 480w,
       ${featuredImageUrl.value.large} 665w,
       ${featuredImageUrl.value.full} 960w`
    )

    // eslint-disable-next-line complexity
    const body = computed(() => {
      if (
        props.isMobile &&
        props.newsItem.appContentBody &&
        props.newsItem.appContentBody !== '<p><br></p>'
      ) {
        return props.newsItem.appContentBody
      }
      if (
        !props.isMobile &&
        props.newsItem.webContentBody &&
        props.newsItem.webContentBody !== '<p><br></p>'
      ) {
        return props.newsItem.webContentBody
      }
      return props.newsItem.body
    })

    // eslint-disable-next-line complexity
    const bodyCkeditor = computed(() => {
      if (
        props.isMobile &&
        props.newsItem.appContentBodyCkeditor &&
        props.newsItem.appContentBodyCkeditor !== '<p><br></p>') {
        return props.newsItem.appContentBodyCkeditor
      }
      if (
        !props.isMobile &&
        props.newsItem.webContentBodyCkeditor &&
        props.newsItem.webContentBodyCkeditor !== '<p><br></p>') {
        return props.newsItem.webContentBodyCkeditor
      }
      return props.newsItem.bodyCkeditor
    })

    // eslint-disable-next-line complexity
    const image = computed(() => {
      if (
        props.isMobile &&
        props.newsItem.appContentImage &&
        Object.keys(props.newsItem.appContentImage).length > 0
      ) {
        return props.newsItem.appContentImage
      }
      if (
        !props.isMobile &&
        props.newsItem.webContentImage &&
        Object.keys(props.newsItem.webContentImage).length > 0
      ) {
        return props.newsItem.webContentImage
      }
      return props.newsItem.image
    })

    // eslint-disable-next-line complexity
    const summary = computed(() => {
      if (
        props.isMobile &&
        props.newsItem.appContentSummary &&
        props.newsItem.appContentSummary !== '') {
        return props.newsItem.appContentSummary
      }
      if (
        !props.isMobile &&
        props.newsItem.webContentSummary &&
        props.newsItem.webContentSummary !== '') {
        return props.newsItem.webContentSummary
      }
      return props.newsItem.summary
    })

    const baseImageUrl = toRef(image.value, 'url')

    const imageUrl = computed(() =>
      imageUrlBuilder(baseImageUrl.value, {
        w: maxWidth,
        h: imageHeight,
        fit: 'crop',
        auto: 'format'
      })
    )

    const featuredImageUrl = computed(() => ({
      half: imageUrlBuilder(baseImageUrl.value, { w: 480, auto: 'format' }),
      full: imageUrlBuilder(baseImageUrl.value, { w: 960, auto: 'format' }),
      large: imageUrlBuilder(baseImageUrl.value, { w: 665, auto: 'format' }),
      small: imageUrlBuilder(baseImageUrl.value, { w: 391, auto: 'format' })
    }))

    return {
      ...props.newsItem,
      body,
      bodyCkeditor,
      summary,
      image,
      formatDate,
      imageUrl,
      featuredImageUrl,
      featuredImageSrcset,
      imageHeight,
      maxWidth
    }
  }
})
